<template>
    <el-container class="container-div" v-loading="loading">
        <el-header>
            <div style="width: 100%;text-align: center;font-size: 32px;margin-top: 10px">
                {{ data.name }}
            </div>
        </el-header>
        <el-main>
            <el-row style="margin-bottom: 20px">
                <el-col :span="7" :offset="2">
                    样式说明
                    <el-button plain disabled>未做题</el-button>
                    <el-button type="primary" plain disabled :autofocus="true">正确题</el-button>
                    <el-button type="success" plain disabled :autofocus="true">已做题</el-button>
                    <el-button type="danger" plain disabled :autofocus="true">错误题</el-button>
                </el-col>
                <el-col :span="1">
                    <i class="el-icon-circle-check" style="font-size: 32px;color: #00a854"></i>
                </el-col>
                <el-col :span="1">
                    <span style="font-size: 24px;line-height: 32px">{{
                        examModel !== '2' ? '∞' : data.rightCount
                        }}</span>
                </el-col>
                <el-col :span="1">
                    <i class="el-icon-circle-close" style="font-size: 32px;color: red"></i>
                </el-col>
                <el-col :span="1">
                    <span style="font-size: 24px;line-height: 32px">{{
                        examModel !== '2' ? '∞' : data.wrongCount
                        }}</span>
                </el-col>
                <el-col :span="2">
                    <el-button :type="question.collect ? 'primary':'danger'" round @click="doCollect">
                        <i :class="question.collect ?'el-icon-star-on':'el-icon-star-off'"></i>
                        {{ question.collect ? '已收藏' : '收藏' }}
                    </el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="danger" round @click="mistake">
                        错误反馈
                    </el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="success" round @click="redo">
                        全部重做
                    </el-button>
                </el-col>
                <el-col :span="1">
                    <el-button icon="el-icon-s-tools" circle @click="setting"></el-button>
                </el-col>
                <el-col :span="2" v-if="examModel === '1'">
                    <el-button type="success" round @click="submit">
                        查看总题情况
                    </el-button>
                </el-col>
            </el-row>

            <el-row :gutter="5" style="margin-bottom: 20px;max-height:200px;overflowX: hidden;overflowY: auto;">
                <!--答题卡-->
                <el-col :span="1" v-for="(item, index) in data.weChatDoExamQuestionVOs" :key="item.id"
                        style="margin-bottom: 4px">
                    <el-button :aria-selected="questionIndex === index" plain :bind="item.id" style="width: 100%"
                               @click="switchQuestion(index)"
                               :type="(!item.answered || examModel === '3' ) ? '':examModel === '1'? 'success': (item.answered && item.yesOrNo)? 'primary':'danger'">
                        {{ item.no }}
                    </el-button>
                </el-col>
            </el-row>
            <el-row>
                <!--题目-->
                <div class="question-div">
                    <span v-html="question.name"></span><br>
                    <span v-html="question.content"></span>
                </div>

                <!--答题-->
                <div class="question-div">
                    <div v-for="(item, index) in question.weChatDoExamAnswerVOs" :key="item.id"
                         style="margin-bottom: 10px;cursor:pointer"
                         @click="((question.answered || examModel === '3') && examModel !== '1') ? false:doAnswer(index)">
                        <i style="font-size: 32px" class="iconfont icon-A_round"
                           v-if="(index === 0  && (examModel === '2' &&(((item.id !== question.answerId && !item.yesOrNo) || !question.answered )) || (examModel === '1' && item.id !== question.answerId)) || (index === 0  && examModel === '3' && !item.yesOrNo))"></i>
                        <i style="font-size: 32px" class="iconfont icon-B_round"
                           v-if="(index === 1  && (examModel === '2' &&(((item.id !== question.answerId && !item.yesOrNo) || !question.answered )) || (examModel === '1' && item.id !== question.answerId)) || (index === 1  && examModel === '3' && !item.yesOrNo))"></i>
                        <i style="font-size: 32px" class="iconfont icon-C_round"
                           v-if="(index === 2  && (examModel === '2' &&(((item.id !== question.answerId && !item.yesOrNo) || !question.answered )) || (examModel === '1' && item.id !== question.answerId)) || (index === 2  && examModel === '3' && !item.yesOrNo))"></i>
                        <i style="font-size: 32px" class="iconfont icon-D_round"
                           v-if="(index === 3  && (examModel === '2' &&(((item.id !== question.answerId && !item.yesOrNo) || !question.answered )) || (examModel === '1' && item.id !== question.answerId)) || (index === 3  && examModel === '3' && !item.yesOrNo))"></i>


                        <i style="font-size: 32px;color: #5397F8" class="el-icon-success"
                           v-if="(examModel === '1' && item.id === question.answerId)||(item.yesOrNo && question.answered && examModel === '2') || (examModel === '3' && item.yesOrNo)"></i>
                        <i style="font-size: 32px;color: #F25E5E" class="el-icon-error"
                           v-if="(item.id === question.answerId && !question.yesOrNo  && question.answered && examModel === '2')"></i>
                        <span v-html="item.content" style="vertical-align: top;line-height: 32px"></span>
                    </div>
                    <el-button v-if="data.type !== 1 || (question.type !== '1')" type="success" @click="zhuguanFlag = true">
                        查看解析
                    </el-button>
                </div>
                <div class="question-div" style="text-align: center">
                    <el-button type="primary" style="margin-right: 50%" @click="pre">上一题</el-button>
                    <el-button type="primary" @click="next">下一题</el-button>
                </div>
                <!--解析-->
                <div class="question-div"
                     v-if="(question.answered && examModel !== '1') || examModel === '3' || (data.type !== 1 && zhuguanFlag)|| (question.type !== '1' && zhuguanFlag)">
                    <div v-html="'<strong>解析：</strong><br>' + question.parse" style="margin: 10px 0 0 0"></div>
                </div>
            </el-row>
        </el-main>

        <el-dialog
                title="系统设置"
                :visible.sync="dialogVisible"
                width="30%">
            <el-form ref="form" label-width="100px">
                <el-form-item label="考试模式">
                    <el-radio-group v-model="examModelNew" :style="{'--colour': '#409EFF'}">
                        <el-radio :label="'1'" style="font-size: 16px">考试模式 <br>&nbsp;&nbsp;（不显示答案和解析）<br>&nbsp;&nbsp;（答题卡中可交卷、查看整体情况）
                        </el-radio>
                        <br>
                        <el-radio :label="'2'" style="margin-top: 20px">做题模式<br>&nbsp;&nbsp;（默认，答错时显示答案和解析）
                        </el-radio>
                        <br>
                        <el-radio :label="'3'" style="margin-top: 20px">背题模式<br>&nbsp;&nbsp;（直接显示答案和解析）
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="连续做对几次自动清除错题" label-width="100px">
                    <el-slider
                            v-model="wrongCount"
                            :min="2"
                            :max="10"
                            show-input>
                    </el-slider>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doExamUserSetting">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog
                title="错误反馈"
                :visible.sync="mistakeVisible"
                width="30%">
            <el-form ref="form" :model="mistakeForm" label-width="80px">
                <el-form-item label="题目">
                    <span v-html="setText(question.name)"></span>
                </el-form-item>
                <el-form-item label="错误类型">
                    <el-radio-group v-model="mistakeForm.type" :style="{'--colour': '#409EFF'}">
                        <el-radio :label="0">图片缺失</el-radio>
                        <br>
                        <el-radio :label="1">答案错误</el-radio>
                        <br>
                        <el-radio :label="2">解析错误</el-radio>
                        <br>
                        <el-radio :label="3">文字错误</el-radio>
                        <br>
                        <el-radio :label="4">其他</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input type="textarea" v-model="mistakeForm.content"
                              placeholder="请简单描述一下当前题目遇到的问题或建议"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="mistakeVisible = false">取 消</el-button>
              <el-button type="primary" @click="mistakeSubmit">提 交</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>

<script>
import {
    getQuestionByRecordId,
    doAnswer,
    doCollect,
    doExamError,
    doExamUserSetting,
    getExamUserSetting
} from "@/api/frontStage/doExam";
import {clearData} from "@/api/frontStage/exam";
import {formatDateTime} from "@/utils/Date";
import {Message} from "element-ui";

export default {
    name: "doExam",
    data() {
        return {
            recordId: this.$route.query.recordId,
            data: '',
            question: '',
            answerId: '',
            questionIndex: '',
            loading: false,
            examModel: '2', //1考试模式 2做题模式 3背题模式
            examModelNew: '2', //1考试模式 2做题模式 3背题模式
            wrongCount: 2,
            dialogVisible: false,
            mistakeVisible: false,
            zhuguanFlag: false,
            mistakeForm: {
                type: 0,
                content: ''
            }
        }
    },
    methods: {
        setText(html) {
            let txt = document.createElement("div");
            txt.innerHTML = html;
            let content = txt.innerText || txt.textContent;
            txt = null;
            return content;
        },
        submit() {
            // 检查是不是都做完了
            var that = this;
            var allCount = 0;
            var doCount = 0;
            var rightCount = 0;
            var icon = 'error'
            allCount = that.data.weChatDoExamQuestionVOs.length;
            for (var i = 0; i < that.data.weChatDoExamQuestionVOs.length; i++) {
                if (that.data.weChatDoExamQuestionVOs[i].answered) {
                    doCount++;
                }
                if (that.data.weChatDoExamQuestionVOs[i].yesOrNo) {
                    rightCount++;
                }
            }
            var msg = '';
            if (rightCount / allCount > 0.6) {
                msg = '恭喜你，闯关成功'
                icon = 'success'
            } else {
                msg = '很遗憾，闯关失败'
            }
            this.$confirm('总题数：' + allCount + "<br>作答数：" + doCount + "<br>正确数：" + rightCount + '<br>正确率（总/正确）：' + parseFloat(rightCount / allCount * 100).toFixed(2) + "%", msg, {
                confirmButtonText: '查看答案',
                cancelButtonText: '继续做题',
                type: icon,
                dangerouslyUseHTMLString: true
            }).then(() => {
                // 查看答案
                this.examModel = '2';
            }).catch(() => {
                // 继续做题
            });
        },
        doCollect() {
            doCollect({questionId: this.question.id}).then(res => {
                this.data.weChatDoExamQuestionVOs[this.questionIndex].collect = (this.data.weChatDoExamQuestionVOs[this.questionIndex].collect ? false : true);
            })
        },
        mistake() {
            this.mistakeVisible = true;
        },
        mistakeSubmit() {
            const data = {
                'questionId': this.question.id,
                'type': this.mistakeForm.type,
                'content': this.mistakeForm.content
            }
            doExamError(data).then(res => {
                this.mistakeVisible = false;
            })
        },
        redo() {
            this.$confirm('确认要清空做题数据，重新做题么？', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true
            }).then(() => {
                // 确认
                clearData({'recordId': this.recordId}).then(res => {
                    this.getQuestionByRecordId();
                })
            }).catch(() => {
                // 取消
            });

        },
        doAnswer(answerIndex) {
            // 当前题目
            var question = this.question;
            // 当前题目
            var questionIndex = this.questionIndex;
            console.log(questionIndex)
            // 全部答案
            var answers = question.weChatDoExamAnswerVOs
            // 当前答案
            var answer = answers[answerIndex];
            // 判断是否正确
            if (answer.yesOrNo) {
                // 正确
                if (!question.answered) {
                    // 未作答正确总数 + 1 已做答不变
                    this.data.rightCount = this.data.rightCount + 1;
                }
                // 问题 正确标识
                this.data.weChatDoExamQuestionVOs[questionIndex].yesOrNo = true;
            } else {
                // 错误
                if (!question.answered) {
                    // 未作答错误总数 + 1 已做答不变
                    this.data.wrongCount = this.data.wrongCount + 1;
                }
                // 问题 正确标识
                this.data.weChatDoExamQuestionVOs[questionIndex].yesOrNo = false;
            }
            // 题目已做
            this.data.weChatDoExamQuestionVOs[questionIndex].answered = true;
            // 答案id
            this.data.weChatDoExamQuestionVOs[questionIndex].answerId = answer.id;
            this.answerId = answer.id;
            if (this.examModel === '3') {
                return;
            }
            const data = {
                'questionId': question.id,
                'recordId': this.recordId,
                'answerId': answer.id,
                'isRight': this.data.weChatDoExamQuestionVOs[questionIndex].yesOrNo ? '0' : '1'
            }
            console.log(this.data)
            doAnswer(data).then(res => {
                console.log(res)
            })
        },
        getRightAnswer() {
            for (var i = 0; i < this.question.weChatDoExamAnswerVOs.length; i++) {
                if (this.question.weChatDoExamAnswerVOs[i].yesOrNo) {
                    return this.question.weChatDoExamAnswerVOs[i].content;
                }
            }
        },
        getRightAnswerId() {
            for (var i = 0; i < this.question.weChatDoExamAnswerVOs.length; i++) {
                if (this.question.weChatDoExamAnswerVOs[i].yesOrNo) {
                    return this.question.weChatDoExamAnswerVOs[i].id;
                }
            }
        },
        async getQuestionByRecordId() {
            this.loading = true;
            // 检查参数正确性
            if (this.recordId == null || this.recordId === '' || this.recordId === undefined) {
                this.goIndex();
            }
            // 查询题目
            await getQuestionByRecordId({'recordId': this.recordId}).then(res => {
                if (res.code === 1) {
                    this.goIndex();
                }
                console.log(res);
                this.data = res.data;
                this.question = res.data.weChatDoExamQuestionVOs[0];
                this.questionIndex = 0;
                if (this.examModel === '3') {
                    this.answerId = this.getRightAnswerId();
                }
                this.loading = false;
            })
        },
        switchQuestion(index) {
            this.zhuguanFlag = false;
            this.question = this.data.weChatDoExamQuestionVOs[index];
            this.questionIndex = index;
            this.answerId = this.data.weChatDoExamQuestionVOs[index].answerId;
            if (this.examModel === '3') {
                this.answerId = this.getRightAnswerId();
            }
        },
        goIndex() {
            this.$router.push({
                path: '/examDetail',
                query: {examId: "0282b9e4-0c58-4dfa-b3f1-ddd29f3fc1a7", examType: 'EXAM_01'}
            });
        },
        async getExamUserSetting() {
            await getExamUserSetting().then(res => {
                this.examModel = res.data.examModel;
                this.wrongCount = res.data.wrongCount;
            })
        },
        setting() {
            this.dialogVisible = true;
            this.examModelNew = this.examModel;
        },
        doExamUserSetting() {
            const data = {
                examModel: this.examModelNew,
                wrongCount: this.wrongCount
            }
            doExamUserSetting(data).then(res => {
                this.examModel = this.examModelNew;
                if (this.examModel === '3') {
                    this.answerId = this.getRightAnswerId();
                } else {
                    this.answerId = this.data.weChatDoExamQuestionVOs[this.questionIndex].answerId;
                    this.question = this.data.weChatDoExamQuestionVOs[this.questionIndex];
                }
                this.dialogVisible = false;
            })
        },
        next(){
            if (this.questionIndex >= this.data.allCount - 1){
                Message.success({message: "已是最后一题"});
            } else {
                this.questionIndex ++;
                this.question = this.data.weChatDoExamQuestionVOs[this.questionIndex];
            }
            console.log(this.data)
            console.log(this.question)
            console.log(this.questionIndex)
        },
        pre(){
            if (this.questionIndex <= 0){
                Message.success({message: "已是第一道题"});
            } else {
                this.questionIndex --;
                this.question = this.data.weChatDoExamQuestionVOs[this.questionIndex];
            }
        }
    },
    filters: {
        formatDateTime(time) {
            return formatDateTime(time);
        },
        // 去除标签
        setText(html) {
            let txt = document.createElement("div");
            txt.innerHTML = html;
            let content = txt.innerText || txt.textContent;
            txt = null;
            return content;
        }
    },
    async created() {
        await this.getExamUserSetting();
        await this.getQuestionByRecordId();
    }
}
</script>

<style scoped>
.container-div {
    margin: 0;
    padding: 0;
    min-height: 1200px;
}

.question-div {
    width: 80%;
    margin: 10px auto 30px auto;
    font-size: 20px;
}

/*::v-deep el-radio {*/
/*    display: block;*/
/*    margin: 10px 0;*/
/*}*/

/*.el-radio /deep/ .el-radio__label {*/
/*    font-size: 20px !important;*/
/*    color: black !important;*/
/*}*/

/*::v-deep .el-radio__input.is-checked + .el-radio__label {*/
/*    color: var(--colour);*/
/*}*/

/*::v-deep .el-radio__input.is-checked .el-radio__inner {*/
/*    background: var(--colour) !important;*/
/*    border-color: var(--colour) !important;*/
/*}*/

/*.el-scrollbar__bar.is-vertical {*/
/*    opacity: 1;*/
/*}*/

/*.circle {*/
/*    width: 25px;*/
/*    height: 25px;*/
/*    border-style: solid;*/
/*    border-width: 1px;*/
/*    border-radius: 50%;*/
/*    -moz-border-radius: 50%; -webkit-border-radius : 50%;*/
/*    border-color: #1E90FF;*/
/*    -webkit-border-radius: 50%;*/
/*}*/

/*.circle-text {*/
/*    font-family:"黑体",Helvetica,"Arial","Microsoft YaHei","宋体",sans-serif;;*/
/*    width: 25px;*/
/*    height: 25px;*/
/*    line-height: 25px;*/
/*    text-align: center;*/
/*    font-size: 15px;*/
/*    font-weight:normal;*/
/*    color: #1E90FF;*/
/*}*/
</style>
