import Request from '@/utils/request';

// 获取工具列表
export const getExamList = (params) => Request.get('/chick/exam/getExamList', { data: { ...params }, loading: false });
// 根据考试id获取考试中的每个分类
export const getExamDetailByExamId = (params) => Request.get('/chick/exam/getExamDetailByExamId', { data: { ...params }, loading: false });

export const getExamAnswerHappeningByUserAndDetailId = (params) => Request.get('/chick/exam/getExamAnswerHappeningByUserAndDetailId', { data: { ...params }, loading: false });

// 通过examId和subjectId获取真题接口
export const getRealListByExamIdSubjectId = (params) => Request.get('/chick/examReal/getRealListByExamIdSubjectId', { data: { ...params }, loading: false });

// 通过examId和subjectId和detailId获取做题记录列表
export const getExaminationRecord = (params) => Request.get('/chick/examWeb/getExaminationRecord', { data: { ...params }, loading: false });

// 通过获取做题记录中的题目等信息
export const getQuestionByRecordId = (params) => Request.get('/chick/examRecord/getQuestionByRecordId', { data: { ...params }, loading: false });

// 生成做题记录
export const createRecord = (params) => Request.post('/chick/examWeb/createRecord', { data: { ...params }, loading: false });

// 通过questionId获取题目 等信息
export const getQuestionByQuestionId = (params) => Request.get('/chick/examQuestion/getQuestionByQuestionId', { data: { ...params }, loading: false });

// 获取答题记录
export const getRecordById = (params) => Request.get('/chick/examRecord/getRecordById', { data: { ...params }, loading: false });

// 做题
export const doAnswer = (params) => Request.post('/chick/examAnswerQuestions/doAnswer', { data: { ...params }, loading: false });

// 清空做题数据
export const clearData = (params) => Request.get('/chick/examAnswerQuestions/clearData', { data: { ...params }, loading: false });

export const getExaminationWrongRecord = (params) => Request.get('/chick/examWeb/getExaminationWrongRecord', { data: { ...params }, loading: false });


export const clearWrongByRecordId = (params) => Request.get('/chick/examWeb/clearWrongByRecordId', { data: { ...params }, loading: false });
